import React, { useCallback } from "react";
import BeSpeakerPopUp from "../Forms/BeSpeakerPopUp/BeSpeakerPopUp";
import { usePopupContext } from "../../context/PopupContext";
import "./BeSpeakerBlock.css";

export default function BeSpeakerBlock(props) {
  const { rectangle49 } = props;

  const { setActive, setContent } = usePopupContext();

  const openBeSpeakerPopup = useCallback(() => {
    setActive(true);
    setContent(<BeSpeakerPopUp />);
  }, [setActive, setContent]);

  return (
    <div className="be-speaker_block">
      <img className="rectangle-49" src={rectangle49} alt="Rectangle 49" />
      <div className="be-speaker-container">
        <img
          className="overlap-group-4"
          src={require('../../img/optimized/commoin/be-expert-block.webp')}
          alt=""
        />
        <div className="be-speaker-h-container">
          <h2 className="be-speaker-h2 montserrat-extra-bold-white-32px">
            Вы крутой эксперт и хотите сделать тысячи женщин лучше?
          </h2>
          <h1 className="be-speaker-h1 montserrat-extra-bold-stratos-48px">
            Присоединяйтесь к клубу в качестве спикера
          </h1>
          <h2 className="be-speaker-h3 inter-normal-white-24px">
            Заполните заявку, и мы свяжемся с вами в ближайшее время.
          </h2>
          <button
            className="be-speaker-btn be-speaker-btn-text inter-medium-white-14px"
            onClick={openBeSpeakerPopup}
          >
            ХОЧУ СТАТЬ ЭКСПЕРТОМ КЛУБА
          </button>
        </div>
      </div>
    </div>
  );
}
