import React, { useState, useEffect, useCallback } from 'react';
import Button from '../Elements/Button/Button';
import Loading from '../../Loader/Loader';
import BC365Service from '../../../services/beautyclub365';
import { useUserContext } from '../../../context/UserContext';
import './AllProducts.css';
import Promocode from '../../Promocode/Promocode';

function AllTariffPayment() {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [existsError, setExistsError] = useState(false);
  const [answerText, setAnswerText] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const { setBasket, products, defaultPrice, setDefaultPrice, userProfile } =
    useUserContext();
  const [isWaitingAnswer, setIsWaitingAnswer] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(products[0][0].id);

  const changePrice = useCallback(
    (e) => {
      const productId = e.target.value;
      setSelectedProductId(productId);
      setDefaultPrice(e.target.getAttribute('data-price'));
      setBasket({
        price: e.target.getAttribute('data-price'),
        name: e.target.getAttribute('data-product'),
        price_clear: e.target.getAttribute('data-cprice'),
        product_id: e.target.getAttribute('data-prid'),
        payment_desc: '',
      });
      setIsDisabled(false);
    },
    [setDefaultPrice, setBasket, defaultPrice, setIsDisabled]
  );

  useEffect(() => {
    if (products[0] && products[0][0]) {
      if (!selectedProductId) {
        const oneMonthProduct = products[0][0];
        setSelectedProductId(oneMonthProduct.id);
        setDefaultPrice(oneMonthProduct.price);
        setBasket({
          price: oneMonthProduct.price,
          name: oneMonthProduct.name,
          price_clear: oneMonthProduct.clear_price,
          product_id: oneMonthProduct.id,
          payment_desc: '',
        });
      } else {
        // Если selectedProductId уже установлен, обновляем basketData с учетом этого выбора
        const selectedProduct = products
          .flat()
          .find((product) => product.id === selectedProductId);
        if (selectedProduct) {
          setBasket({
            price: selectedProduct.price,
            name: selectedProduct.name,
            price_clear: selectedProduct.clear_price,
            product_id: selectedProduct.id,
            payment_desc: '',
          });
        }
      }
      setIsDisabled(false);
    }
  }, [
    products,
    setDefaultPrice,
    setBasket,
    setIsDisabled,
    setSelectedProductId,
    selectedProductId,
  ]);

  const db = new BC365Service();
  useEffect(() => {
    const { fio, email } = userProfile;
    db.openPayment({
      first_name: fio,
      email,
    }).then((answer) => {
      //console.log(answer);
    });
  }, []);
  return (
    <div className="allProductsWrapper">
      {isWaitingAnswer && (
        <div className="loaderWrapper">
          <Loading width={100} />
        </div>
      )}
      {!isWaitingAnswer && (
        <>
          <h2 className="allProductsFormTitle">
            Выберите вариант подписки на тариф1{' '}
            <span id="pink-text">
              "{products[0] && products[0][0].short_name}"
            </span>
          </h2>
          <div className="blockProduct">
            <div className="blockProductSub">
              <div className="blockProductSubRadio">
                <input
                  id={'product-' + products[0][0].id}
                  className="prRadio"
                  type="radio"
                  name="product"
                  data-price={products[0][0].price}
                  data-product={products[0][0].name}
                  value={products[0][0].id}
                  data-prid={products[0][0].id}
                  data-cprice={products[0][0].clear_price}
                  checked={selectedProductId === products[0][0].id}
                  onChange={changePrice}
                />
                <label
                  htmlFor={'product-' + products[0][0].id}
                  className="prLabel"
                >
                  {products[0] && products[0][0].period_name}
                </label>
              </div>
              <span className="prPrice">
                Цена: <strong>{products[0] && products[0][0].price}</strong>
                {products[0] &&
                  products[0][0].economy_discount > 0 &&
                  products[0][0].base_price > 0 && (
                    <p className="oldPricePopup">
                      {products[0][0].base_price} руб.
                    </p>
                  )}
              </span>
            </div>
            <div className="blockProductSub">
              <div className="blockProductSubRadio">
                <input
                  className="prRadio"
                  id={'product-' + products[1][1].id}
                  type="radio"
                  name="product"
                  data-price={products[1][1].price}
                  data-product={products[1][1].name}
                  value={products[1][1].id}
                  data-prid={products[1][1].id}
                  data-cprice={products[1][1].clear_price}
                  checked={selectedProductId === products[1][1].id}
                  onChange={changePrice}
                />
                <label
                  htmlFor={'product-' + products[1][1].id}
                  className="prLabel"
                >
                  {products[1] && products[1][1].period_name}
                </label>
              </div>
              <span className="prPrice">
                Цена: <strong>{products[1] && products[1][1].price}</strong>
                {products[1] &&
                  products[1][1].economy_discount > 0 &&
                  products[1][1].base_price > 0 && (
                    <p className="oldPricePopup">
                      {products[1][1].base_price} руб.
                    </p>
                  )}
              </span>
            </div>
            <div className="blockProductSub">
              <div className="blockProductSubRadio">
                <input
                  className="prRadio"
                  id={'product-' + products[3][0].id}
                  type="radio"
                  name="product"
                  value={products[3][0].id}
                  data-price={products[3][0].price}
                  data-product={products[3][0].name}
                  data-prid={products[3][0].id}
                  data-cprice={products[3][0].clear_price}
                  checked={selectedProductId === products[3][0].id}
                  onChange={changePrice}
                />
                <label
                  htmlFor={'product-' + products[3][0].id}
                  className="prLabel"
                >
                  {products[3] && products[3][0].period_name}
                </label>
              </div>
              <span className="prPrice">
                Цена: <strong>{products[3] && products[3][0].price}</strong>
                {products[3] &&
                  products[3][0].economy_discount > 0 &&
                  products[3][0].base_price > 0 && (
                    <p className="oldPricePopup">
                      {products[3][0].base_price} руб.
                    </p>
                  )}
              </span>
            </div>
          </div>
          <Promocode disabled={isDisabled} />
          <div>
            <Button
              type="button"
              value="Перейти к оплате"
              fields={{ email }}
              setIsWaitingAnswer={setIsWaitingAnswer}
              method="buy"
              validate={setErrors}
              existsErrors={setExistsError}
              setAnswerText={setAnswerText}
              disabled={isDisabled}
              onClick={dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                  coupon: 'SUMMER_SALE',
                  items: [
                    {
                      item_name:
                        'Сертификат на подписку BeautyClub365 на 1 месяц',
                    },
                  ],
                },
              })}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AllTariffPayment;
