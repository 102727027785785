import React, {useState} from "react";
import Auth from "../Auth/Auth";
import Register from "../Register/Register";
import Loading from "../../Loader/Loader";
import "./AuthOrRegister.css";

export default AuthOrRegister = () => {

	const authErrors = {
		login: '',
		password: '',
	};

	const regErrors = {
		name: '',
		secondName: '',
		email: '',
		phone: '',
		password: '',
		repeatPassword: '',
	};

	const [isActiveAuth, setIsActiveAuth] = useState(true);
	const [isWaitingAnswer, setIsWaitingAnswer] = useState(false);
	const [errorsAuth, setErrorsAuth] = useState(authErrors);
	const [errorsReg, setErrorssReg] = useState(regErrors);
	const [existsError, setExistsError] = useState(false);

	const activateForm = () => {
		setIsActiveAuth(!isActiveAuth);
	};

	return (
		<div>
			<div className="logo-icon-dark center-logo" />
			{
				isWaitingAnswer &&
				<div className="loaderWrapper">
					<Loading width={100} />
				</div>
			}
			{
				!isWaitingAnswer &&
				<>
				<div className="wrapper-auth-reg">
					<div className="sub-wrapper-auth-reg">
						<span className={isActiveAuth? "wrapper-form active" : "wrapper-form"} onClick={activateForm}>Вход</span>
						<span className={!isActiveAuth? "wrapper-form active" : "wrapper-form"} onClick={activateForm}>Регистрация</span>
					</div>
				</div>
				{isActiveAuth && <Auth setIsWaitingAnswer={setIsWaitingAnswer} errors={errorsAuth} setErrors={setErrorsAuth} existsError={existsError}
				 setExistsError={setExistsError} />}
				{!isActiveAuth && <Register setIsWaitingAnswer={setIsWaitingAnswer} errors={errorsReg} setErrors={setErrorssReg} existsError={existsError}
				 setExistsError={setExistsError} />}
				</>
			}
		</div>
	);
}
