import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import MainMenu from "../../components/Menu/Main/MainMenu";
import MobileMenu from "../../components/Menu/Mobile/MobileMenu";
import menuMobile from "../../img/gamburger-menu-mobile.png";
import menuMobileOpened from "../../img/gamburger-menu-mobile-opened.png";
import { useUserContext } from "../../context/UserContext";
import { usePopupContext } from "../../context/PopupContext";
import { PROXY_URL } from "../../shared/constants";
import AuthOrRegister from "../Forms/AuthOrRegister/AuthOrRegister";
import BC365Service from "../../services/beautyclub365";
import { YM_KEY } from "../../shared/constants";
import { useCookies } from "react-cookie";
import BeSpeakerPopUp from "../Forms/BeSpeakerPopUp/BeSpeakerPopUp";
import "./Header.css";
import Certificates from '../Forms/Certificates/Certificates'

export default Header = (props) => {
  const { scrollPosition } = props;

  const headerBlock = useRef();

  const { setActive, setContent, needContinue, setNeedContinue, setType } =
    usePopupContext();
  const {
    userProfile,
    setUserProfile,
    userPublicData,
    setIsCertificate,
    setShowTestDrive,
    setUserCanUseTrial,
    setJustBuyCertificate,
    settings,
    setSettings
  } = useUserContext();

  const [isOpenedMenu, setIsOpenedMenu] = useState(false);
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);

  const [isOpenedUserMenu, setIsOpenedUserMenu] = useState(false);

  const buyCertificate = () => {
    const { online } = userProfile;
    setJustBuyCertificate(true);
    setType('click_certificate');
    setActive(true);
    if (online) {
      setIsCertificate(true);
      setContent(<Certificates />);
    } else {
      setContent(<CheckEmailCertificate />);
    }
  };

  const {show_button_gift_certificate} = settings;
  const showButtonGiftCertificate = show_button_gift_certificate === 'Y';

  const openBeSpeakerPopup = useCallback(() => {
    setActive(true);
    setContent(<BeSpeakerPopUp />);
  }, [setActive, setContent]);

  useEffect(() => {
    const { online } = userProfile;
    setIsUserAuthorized(online);
    if (online && !needContinue) {
      setActive(false);
      setContent("");
    }
  }, [
    setIsUserAuthorized,
    isUserAuthorized,
    setActive,
    setContent,
    userProfile
  ]);

  const openAuthRegisterPopup = useCallback(() => {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({'event': 'vhod_registr1'});
    }
    if (typeof ym !== 'undefined') {
      ym(YM_KEY, "reachGoal", "voyti");
    }
    setActive(true);
    setContent(<AuthOrRegister />);
  }, [setActive, setContent]);

  const toggleMenu = useCallback(() => {
    setIsOpenedMenu(!isOpenedMenu);
  }, [setIsOpenedMenu, isOpenedMenu]);

  const iconMenu = isOpenedMenu ? menuMobileOpened : menuMobile;

  const actionUserMenu = useCallback(() => {
    setIsOpenedUserMenu(!isOpenedUserMenu);
  }, [setIsOpenedUserMenu, isOpenedUserMenu]);

  const [_, setCookie, removeCookie] = useCookies(["PHPSESSID"]);

  const logout = (e) => {
    e.preventDefault();
    const db = new BC365Service();
    setIsOpenedUserMenu(false);
    db.logout().then((answer) => {
      if (answer["is_offline"]) {
        setUserProfile({
          fio: "",
          email: "",
          avatar: "",
          login: "",
          token: "",
          online: false,
          user_id: 0,
        });
        setShowTestDrive(true);
        setUserCanUseTrial(false);
        removeCookie("PHPSESSID");
        setNeedContinue(false);
        setIsCertificate(false);
      }
    });
  };

  return (
    <div
      className="header"
      id="top"
      ref={headerBlock}
      style={scrollPosition > 0 ? { opacity: 0.85 } : { opacity: 1 }}
    >
      <div className="header-container">
        <Link className="logo-icon" to="/">main</Link>
        <img
          className="gamburger-menu-mobile"
          src={iconMenu}
          onClick={toggleMenu}
          alt="menu"
        />
        <div className="btn-container">
          {showButtonGiftCertificate && (
            <button
              className="buy_certificate_btn text_buy_certificate_btn"
              onClick={buyCertificate}
            >
              подарить сертификат
            </button>
          )}
          {!isUserAuthorized && (
            <button
              className="log_in_btn text_log_in_btn"
              onClick={openAuthRegisterPopup}
            >
              войти
            </button>
          )}
          {isUserAuthorized && (
            <>
              <Link className="log_in_btn text_log_in_btn" to="/market/"> курсы </Link>
              <span className="logoSeparator"></span>
              {userPublicData["typeAvatar"] === "image" && (
                <img
                  src={PROXY_URL + "/upload" + userPublicData["avatar"]}
                  alt="logo"
                  className="logoAvatar"
                />
              )}
              {userPublicData["typeAvatar"] === "text" && (
                <span className="textAvatar">{userPublicData["avatar"]}</span>
              )}
              <span className="logoTitle" onClick={actionUserMenu}>
                {userPublicData["name"]}
              </span>
              {isOpenedUserMenu && (
                <div className="userMenu">
                  <a
                    href={PROXY_URL + "/personal/subscription-feed/"}
                    className="bold"
                  >
                    Личный кабинет
                  </a>
                  <a
                    href=""
                    onClick={(e) => {
                      logout(e);
                    }}
                  >
                    Выйти
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {!isOpenedMenu && <MainMenu {...props} />}
      {isOpenedMenu && (
        <MobileMenu
          {...props}
          userPublicData={userPublicData}
          setIsOpenedMenu={setIsOpenedMenu}
          openAuthRegisterPopup={openAuthRegisterPopup}
          logout={logout}
        />
      )}
    </div>
  );
};
