import React, { useState, useEffect } from 'react';
import { useMarketContext } from '../../context/MarketContext';
import './PromotionLine.css';
import PaymentForm from '../Forms/AllProducts/PaymentForm';
import CheckEmail from '../Forms/CheckEmail/CheckEmail';
import { usePopupContext } from '../../context/PopupContext';
import { useUserContext } from '../../context/UserContext';

export default function PromotionLine() {
  const [expanded, setExpanded] = useState(false);
  const [buttonText, setButtonText] = useState('Подробнее');
  const [isVisible, setIsVisible] = useState(true);

  const handleExpand = () => {
    setExpanded(true);
    setButtonEvent(() => handleCollapse);
  };

  const [buttonEvent, setButtonEvent] = useState(() => handleExpand);

  const handleCollapse = () => {
    setExpanded(false);
    setButtonText('Подробнее');
    setButtonEvent(() => handleExpand);
  };

  const { setActive, setContent, setType } = usePopupContext();

  const { userProfile } = useUserContext();

  const joinToUs = () => {
    dataLayer.push({ event: 'join' });
    setType('click_button_site');
    const { online } = userProfile;
    setActive(true);
    if (online) {
      setContent(<PaymentForm />);
      // window.location.assign('/personal/subscription-management/');
    } else {
      setContent(<CheckEmail />);
    }
  };

  const { config } = useMarketContext();

  const [finishTimer, setFinishTimer] = useState(0);
  const [[diffDays, diffH, diffM, diffS], setDiff] = useState([0, 0, 0, 0]);
  const [tick, setTick] = useState(false);

  useEffect(() => {
    const timerID = setInterval(() => setTick(!tick), 1000);
    return () => clearInterval(timerID);
  }, [tick]);

  useEffect(() => {
    const tms = Date.now() / 1000;

    if (tms >= config['start_timer'] && tms <= config['end_timer']) {
      setIsVisible(true);
      setFinishTimer(config['end_timer']);
      const diff = config['end_timer'] - tms;
      setDiff([
        Math.floor(diff / 86400),
        Math.floor((diff / 3600) % 24),
        Math.floor((diff / 60) % 60),
        Math.floor(diff % 60),
      ]);
    } else {
      setIsVisible(false);
    }

    if (tms >= config['end_timer']) {
      setIsVisible(false);
    }
  }, [config, tick]);

  console.log(config);

  const gotToLink = (link) => {
    window.location.href = link;
  };

  const getDaysString = (days) => {
    if (days % 10 === 1 && days % 100 !== 11) {
      return 'день';
    } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
      return 'дня';
    } else {
      return 'дней';
    }
  }

  const getRemainingString = (days) => {
    if (days === 1 || (days % 10 === 1 && days % 100 !== 11)) {
      return 'остался';
    } else {
      return 'осталось';
    }
  }

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className="promotion-line-wrapper"
      // style={{ backgroundColor: config['discount_color'] }}
    >
      {expanded ? (
        <div className="promotion-line-text">
          <div className='wrap-1'>
            {config['discount_description']}
            <div>{`${getRemainingString(diffDays)} ${diffDays} ${getDaysString(diffDays)} ${diffH
              .toString()
              .padStart(2, '0')}:${diffM.toString().padStart(2, '0')}:${diffS
              .toString()
              .padStart(2, '0')}`}</div>
          </div>
          <button onClick={handleCollapse} className="promo-close-btn">
            ×
          </button>
          <button
            className="promotion-line-btn"
            // style={{ backgroundColor: config['button_color'] }}
            // onClick={joinToUs}
            onClick={() => gotToLink(config['button_link'])}
          >
            {config['button_text']}
          </button>
        </div>
      ) : (
        <>
          <div className="promotion-line-text">
            <div className="wrap-1">
              {config['discount_text']}
            </div>
          </div>
          <button
            onClick={buttonEvent}
            className="promotion-line-btn"
            // style={{ backgroundColor: config['button_color'] }}
          >
            {buttonText}&nbsp;|&nbsp;
            <span>{`${getRemainingString(diffDays)} ${diffDays} ${getDaysString(diffDays)} ${diffH
              .toString()
              .padStart(2, '0')}:${diffM.toString().padStart(2, '0')}:${diffS
              .toString()
              .padStart(2, '0')}`}</span>
          </button>
        </>
      )}
    </div>
  );
}
