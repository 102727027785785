export const PROXY_URL = 'https://beautyclub365.ru';
export const SITE_NAME = 'BeautyClub365';
export const CP_PUBLIK_KEY_RU = 'pk_2f17ddcd63ebd27ffbd86f0f70840';
export const CP_PUBLIK_KEY_SNG = 'pk_f85b51644ff6a4caafad768c44624';
export const YM_KEY = '71776846';
export const TRY_SUBSON_5_DAYS_POPUP_TIMER = 5000;
export const TRY_SUBSON_5_DAYS_POPUP_TIMER_20 = 15000;
export const COUNT_COURSES_PER_PAGE = 6;
export const MIN_WIDTH_TO_GET_MOBILE_IMAGE = 500;
export const USER_TYPES = {'nau': 'not_auth_user', 'abns': 'auth_but_not_subs', 'aas': 'auth_and_subs'};
