import { createContext, useContext, useMemo, useState, useEffect } from "react";

const initialState = {
  isActive: false,
  setActive: () => {},
  content: '',
  needContinue: false,
  setContent: () => {},
  setNeedContinue: () => {},
  wasClosedPopup: false,
  setWasClosedPopup: () => {},
  wasOpenedPopupBefore: false,
  setWasOpenedPopupBefore: () => {},
  fromTrialPopup: false,
  setFromTrialPopup: () => {},
  anonymousCanGetTrial: true,
  setAnonymousCanGetTrial: () => {},
  typePopup: '',
  setType: () => {},
};

const PopupContext = createContext(initialState);

export const PopupProvider = ({ children }) => {

	const [isActive, setActive] = useState(initialState.isActive);
	const [content, setContent] = useState(initialState.content);
	const [needContinue, setNeedContinue] = useState(initialState.needContinue);
	const [wasClosedPopup, setWasClosedPopup] = useState(initialState.wasClosedPopup);
	const [wasOpenedPopupBefore, setWasOpenedPopupBefore] = useState(initialState.wasOpenedPopupBefore);
	const [fromTrialPopup, setFromTrialPopup] = useState(initialState.fromTrialPopup);
	const [anonymousCanGetTrial, setAnonymousCanGetTrial] = useState(initialState.anonymousCanGetTrial);
	const [typePopup, setType] = useState(initialState.setType);

	useEffect(() => {
		setWasOpenedPopupBefore(isActive);
	}, [
		isActive,
		wasOpenedPopupBefore,
	]);

  	const value = useMemo(() => {
		return {
			isActive,
			setActive,
			content,
			setContent,
			needContinue,
			setNeedContinue,
			wasClosedPopup,
			setWasClosedPopup,
			wasOpenedPopupBefore,
			setWasOpenedPopupBefore,
			fromTrialPopup,
			setFromTrialPopup,
			anonymousCanGetTrial,
			setAnonymousCanGetTrial,
			typePopup,
			setType,
		};
  }, [
	isActive,
	setActive,
	content,
	setContent,
	needContinue,
	setNeedContinue,
	wasClosedPopup,
	setWasClosedPopup,
	wasOpenedPopupBefore,
	setWasOpenedPopupBefore,
	fromTrialPopup,
	setFromTrialPopup,
	anonymousCanGetTrial,
	setAnonymousCanGetTrial,
	typePopup,
	setType,
  ]);

  return <PopupContext.Provider value={value}>{children}</PopupContext.Provider>;
};

export const usePopupContext = () => {
  return useContext(PopupContext);
};
