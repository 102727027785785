import React, { useCallback, useEffect, useState } from "react";
import Input from "../Elements/Input/Input";
import Button from "../Elements/Button/Button";
import Loading from "../../Loader/Loader";
import "./SendPromo.css";

export default SendPromo = () => {
	const [sent, isSent] = useState(false);
	const [email, setEmail] = useState('');
	const [isWaitingAnswer, setIsWaitingAnswer] = useState(false);
	const [errors, setErrors] = useState({
		email: '',
	});
	const [existsError, setExistsError] = useState(false);
	const [answerText, setAnswerText] = useState('');
	const { be } = errors;

	useEffect(() => {
		if (answerText !== '') {
			isSent(true);
		}
	}, [
		answerText
	]);
	
	return (
		<div className="forgotWrapper">
			<div className="logo-icon-dark center-logo" />
			{
				isWaitingAnswer &&
				<div className="loaderWrapper">
					<Loading width={100} />
				</div>
			}
			{
				!isWaitingAnswer &&
				<>
					<h1 className="formTitle">Пригласить друга</h1>
					<h2 className="formTitle">Введите e-mail для отправки промокода</h2>
					<Input
						labelText="E-mail"
						type="text"
						placeholder="miss1265@gmail.com"
						required={true}
						setValue={setEmail}
						errorText={errors.email}
						width="400px"
						existsError={existsError}
						setExistsError={setExistsError}
					/>
					{answerText !== '' && <span className="beckEndSuccess">{answerText}</span>}
					{typeof be !== 'undefined' && <span className="beckEndError">{be}</span>}
					{!sent && <Button 
						type="button" 
						value="Отправить приглашение" 
						fields={{email}} 
						setIsWaitingAnswer={setIsWaitingAnswer}
						method="sendPromo"
						validate={setErrors}
						existsErrors={setExistsError}
						setAnswerText={setAnswerText}
					/>}
				</>
			}
		</div>
	);
}
