import React from 'react';
import './TariffDetailPopUp.css';

function BasicTariffDetailPopup() {
  return (
    <div className="tariffDetail-container">
      <h1 className="tariffDetail-h1">Тариф «БАЗОВЫЙ»</h1>
      <p className="tariffDetail-description">
        Это тариф для тех, кто хочет максимум пользы при минимуме финансовых
        затрат. <br /> <br /> У вас появится возможности 3 раза в неделю
        заниматься йогой, укреплять тело в асанах, делать дыхательные практики -
        пранаямы и стабилизировать психику в медитациях. А еще трижды в неделю -
        пилатес, который укрепит мышцы, сгонит лишнюю «воду» и сделает вашу
        фигуру еще более привлекательной. <br /> <br /> На трех эфира
        омолаживающей гимнастики от Аннетт вас ждут упражнения и массажи для
        устранения отеков, формирования красивого овала, разглаживания морщин,
        улучшения цвета и турнира кожи. Аннетт будет делать упражнения в прямом
        эфире, вам нужно будет только повторять! Хотите больше? Тогда вам нужен
        другой тариф «Только Аннетт» или «Все включено». А в «Базовом» вы будете
        встречаться с Меланнетт на омолаживающих практиках 3 раза в неделю.{' '}
        <br /> <br /> На «Базовом» тарифе вы получите ответы психолога на самые
        важные запросы - как выстраивать отношения, решать конфликты с любимым,
        сохранять свои личные границы, но не превращать жизнь в постоянное
        противоборство, как найти себя и свое место в жизни. <br /> <br /> И
        освоите навык приготовление полезных блюд на каждый день и для
        торжественных случаев (1 эфир в неделю). <br /> <br />
      </p>
    </div>
  );
}

export default BasicTariffDetailPopup;
