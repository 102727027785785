import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import "./styleguide.css";
import App from "./App";

ReactDOM.createRoot(
  document.getElementById("root"),
).render(
  <App />
);
