import React from "react";
import "./Frame60.css";

function Frame60(props) {
  const { view, spanText, className } = props; 

  return (
    <div className={`frame-60-1 ${className || ""}`}>
      <div className="view-14" style={{ backgroundImage: `url(${view})` }}></div>
      <div className="text-14 montserrat-extra-bold-stratos-18px">
        <span className="span-20 montserrat-extra-bold-stratos-18px">{spanText}</span>
      </div>
    </div>
  );
}

export default Frame60;
