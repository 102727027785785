import React from "react";
import "./ResultsBlock.css";
import PhotoCarousel from "../PhotoCarousel/PhotoCarousel";

function ResultsBlock(props) {

  const {
    innoRef,
  } = props;

  return (
    <div className="results_block" id="results_block">
      <div ref={innoRef} className="results-pointBlock" id="results"></div>
      <div className="text-container">
        <h1 className="text-70 montserrat-extra-bold-stratos-48px">
          Вы придете к результату, о котором мечтали
        </h1>
        <h2 className="text-71 inter-normal-stratos-24px">
          Посмотрите, чего уже достигли наши участницы
        </h2>
      </div>
      <PhotoCarousel />
    </div>
  );
}

export default ResultsBlock;
