import React, { useState } from "react";
import Loading from "../Loader/Loader";
import Speaker from "../Popups/Speaker/Speaker";
import { usePopupContext } from "../../context/PopupContext";
import "./ExpertsBlock.css";

function ExpertsBlock(props) {

  const {
    innoRef,
    speakersList,
    waitingMoreSpeakers,
    speakersCount,
  } = props;

  const { setActive, setContent } = usePopupContext();

  const [showMoreButton, setShowMoreButton] = useState(true);

  let {
    total,
    setTotal,
  } = props;

  const loadMoreSpeakers = () => {
    total += 3;
    setTotal(total);
    if (total >= speakersCount) {
      setShowMoreButton(false);
    }
  };

const ruKeys = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
    'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
    'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
    'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
    'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
    'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya'
}

function transliterate(word) {
    return word.split("").map((letter) => {
        const lowLetter = letter.toLowerCase();
        const en = ruKeys[lowLetter] ?? letter;
        return lowLetter === letter ? en : en.toUpperCase();
    }).join("");
}


const showSpeakerDetailInfo = (e, photo, text, name, previewText) => {
  e.preventDefault();
  dataLayer.push({'event': `${transliterate(name)}`});
  setContent('');
  setActive(true);
  setContent(
    <Speaker 
    photo={photo}
    text={text}
    name={name}
    previewText={previewText}
    />
    );
  };
  
  return (
    <div className="experts_block" id="experts_block">
      <div ref={innoRef} className="experts-pointBlock" id="experts"></div>
      <h1 className="text-36 montserrat-extra-bold-stratos-48px">
        Поменять жизнь вам помогут топовые эксперты из разных сфер
      </h1>
      <div className="box-experts_block">
        {
          speakersList.length > 0 && speakersList.map((speaker, key) => {
            const detailPhoto = (speaker.detailhotoOpt !== '') ? speaker.detailhotoOpt : speaker.detailhoto;
            const photo = (speaker.photoOpt !== '') ? speaker.photoOpt : speaker.photo;
            return (
              <div className="box" key={'speaker' + key}>
                <div className="image-experts_block">
                  <img src={photo} alt="" />
                </div>
                <div className="content">
                  <h2 className="montserrat-extra-bold-stratos-18px">{speaker.name}</h2>
                  <p className="inter-normal-stratos-14px">
                    {speaker.previewText}
                  </p>
                  <button href="" className="btn-experts_block" onClick={
                    (e) => showSpeakerDetailInfo(e, detailPhoto, speaker.detailText, speaker.name, speaker.previewText)
                  }>
                    о спикере
                  </button>
                </div>
              </div>
            )
          })
        }
      </div>
      <div>
        {waitingMoreSpeakers && <Loading width="100" />}
      </div>

      {!waitingMoreSpeakers && showMoreButton && <button id="load-more" onClick={loadMoreSpeakers}>Показать больше спикеров</button>}
    </div>
  );
}

export default ExpertsBlock;
