import React from "react";
import "./Speaker.css";
import { usePopupContext } from "../../../context/PopupContext";

function Speaker(props) {
  const { photo, text, name, previewText } = props;

  const { isActive, setActive } = usePopupContext();

  const closeModal = () => {
    setActive(!isActive);
  };

  let htmlName = <h1>{name}</h1>;
  if (name.includes(" ")) {
    const spl = name.split(" ");
    htmlName = (
      <h1>
        {spl[0]} <span>{spl[1]}</span>
      </h1>
    );
  }

  return (
    <>
      <div className="speakerWrapper">
        <div className="speakerBackWrap">
          <span
            className="imgBack"
            style={{ backgroundImage: `url(${photo})` }}
          ></span>
          <span className="speakerBack"></span>
        </div>
        {htmlName}
        <p className="speeker_description">{previewText}</p>
        <div
          className="spreakerContent"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        <div className="back_btn_container">
          <img
            className="back_btn_arrow_icon"
            onClick={closeModal}
            src={require("../../../img/back_btn_arrow.png")}
            alt=""
          />
          <button className="back_btn_arrow" onClick={closeModal}>
            Назад
          </button>
        </div>
      </div>
    </>
  );
}

export default Speaker;
