import React from "react";
import "./CommunityBlock.css";

function CommunityBlock() {
  return (
    <div className="community_block">
      <div className="h1-community">
        <h1 className="h1-community_block">
          Время стать лучшей версией себя!
          <span style={{color: "#ffcbea"}}>
            Присоединяйтесь к BeautyClub365
          </span>
        </h1>
      </div>
      <img
        className="rectangle50-community_block"
        src={require("../../img/optimized/commoin/Rectangle50.webp")}
        alt=""
      />
      <img
        className="pics-community_block"
        src={require("../../img/optimized/commoin/woman.webp")}
        alt=""
      />
    </div>
  );
}

export default CommunityBlock;
