import React from "react";
import Loading from "../../Loader/Loader";
import "./TrialBadAnswer.css";

export default TrialBadAnswer = () => {


	return (
		<div className="allProductsWrapper">
			<div className="loaderWrapper">
				<Loading width={100} />
			</div>
			<h3 className="formTitleTRBefore">Триальный период Вам не доступен или уже подключён</h3>
		</div>
	);
}
