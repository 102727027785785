import React from "react";
import loading from "../../img/loading.gif";
import './Loader.css';

const Loader = ({ width }) => {
  return(
      <div className="loader">
		<img src = {loading} alt = "loader" style={{width: `${width}px`}} />
      </div>
  );
};

export default Loader;
